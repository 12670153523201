export const pageWithoutPagesMappingData: Record<string, string> = {
  'driver-privacy-policy': 'driver-privacy-policy',
  'quy-trinh-thuc-hien-don-hang-ahamove':
    'quy-trinh-thuc-hien-don-hang-ahamove',
  'khach-hang': 'khach-hang',
  'tai-xe': 'tai-xe',
  'giao-hang-trong-ngay-sameday': 'giao-hang-trong-ngay-sameday',
  'tin-tuc': 'tin-tuc',
  '18713-2': 'dang-ky-tai-xe-thanh-cong-autosms',
  '18741-2': 'dang-ky-khach-hang-thanh-cong-sameday',
  'giao-hang-4h': 'giao-hang-trong-ngay',
  'bao-tri-dong-gia': 'dich-vu-ban-yeu-cau-hien-khong-kha-dung',
  'dang-ky-thanh-cong-trong-ngay': 'dang-ky-thanh-cong-giao-hang-trong-ngay',
  'autosms-driver': 'autosms-driver',
  'tt-tmdt-momo':
    'huong-dan-thanh-toan-don-hang-thuong-mai-dien-tu-bang-vi-momo',
  '4h-pause': 'dich-vu-4h-tam-thoi-gian-doan',
  'job-dashboard': 'job-dashboard',
  'tuyen-dung': 'tuyen-dung',
  'dai-hoi-tai-xe-2019': 'dai-hoi-tai-xe-2019',
  'tin-aha-climbers': 'tin-aha-climbers',
  'dang-ky-giao-hang-lien-tinh': 'giao-hang-lien-tinh',
  'topup-ahamove-by-vnpay-qr':
    'huong-dan-nap-tien-vao-tai-khoan-khach-hang-ahamove-bang-vnpay-qr-code',
  txmsg2020: 'mung-nam-moi-qua-phoi-phoi',
  'quy-dinh-bao-hiem-hang-hoa-giao-hang-lien-tinh':
    'quy-dinh-bao-hiem-hang-hoa-giao-hang-lien-tinh',
  'giao-gan': 'giao-gan-ahamove',
  'dang-ky-khach-hang-thanh-cong': 'dang-ky-khach-hang-thanh-cong',
  'huong-dan-su-dung-tai-khoan-cod-ahamove':
    'huong-dan-su-dung-tai-khoan-cod-ahamove',
  'phuong-thuc-thanh-toan-va-chinh-sach-tra-truoc-cua-ahamove':
    'phuong-thuc-thanh-toan-va-chinh-sach-tra-truoc-cua-ahamove',
  'cac-kenh-ho-tro-khach-hang-ahamove': 'cac-kenh-ho-tro-khach-hang-ahamove',
  'gioi-thieu-tinh-nang-giao-hang-tan-tay':
    'gioi-thieu-tinh-nang-giao-hang-tan-tay-moi-cho-khach-hang',
  'gioi-thieu-tinh-nang-gui-sms-theo-doi-hanh-trinh-cho-khach-hang':
    'gioi-thieu-tinh-nang-gui-sms-theo-doi-hanh-trinh-cho-nguoi-nhan-hang',
  'giao-hang-ngoai-thanh': 'giao-hang-ngoai-thanh',
  'nap-tai-khoan-tra-truoc-ahamove': 'nap-tai-khoan-tra-truoc-ahamove',
  'merchant-soi-bien':
    'soi-bien-chuoi-ban-le-thuc-pham-chat-luong-vi-muc-tieu-bao-ve-suc-khoe-va-nang-cao-gia-tri-nong-san-viet',
  'merchant-fresh-garden':
    'fresh-garden-thuong-hieu-banh-tuoi-moi-ngay-and-bai-toan-giao-hang-sieu-toc',
  'merchant-sakuko': 'sakuko-thuong-hieu-ban-le-hang-nhat-noi-dia-hang-dau',
  'ahamove-haravan': 'ahamove-and-haravan',
  'merchant-lazada': 'lazada-nen-tang-thuong-mai-dien-tu-hang-dau-dna',
  'merchant-pizza-4ps':
    'pizza-4ps-tu-phuong-cham-delivering-wow-sharing-happiness-toi-hanh-trinh-nang-tam-dinh-nghia-giao-do-an',
  'merchant-saigon-coop': 'sai-gon-coop-hop-tac-xa-ty-do',
  'merchant-heineken': 'drinkies-du-an-magento-commerce-cua-heineken',
  'merchant-fahasa': 'fahasa-va-hanh-trinh-giao-hang-thoi-chuyen-doi-so',
  'merchant-greenfeed':
    'greenfeed-dong-hanh-den-thanh-cong-cung-khach-hang-chan-nuoi',
  'merchant-thecoffeehouse':
    'the-coffee-house-thuong-hieu-ca-phe-ket-noi-voi-khach-hang-thong-qua-ung-dung-cong-nghe',
  'merchant-f99': 'f99-fruits-cho-hoa-qua-thoi-ky-40-va-cu-but-pha-ngoan-muc',
  'dang-ky-truck-thanh-cong': 'dang-ky-truck-thanh-cong',
  onwheel: 'onwheel',
  'aha-se-chia-ds':
    'aha-se-chia-danh-sach-quan-an-hang-ganh-rong-via-he-tiep-tuc-cap-nhat',
  'aha-loyalty': 'aha-loyalty',
  'aha-chia-se-cam-on': 'aha-chia-se-cam-on',
  'dang-ky-khach-hang-truck-thanh-cong': 'dang-ky-khach-hang-truck-thanh-cong',
  'chinh-sach-dich-vu-mua-ho': 'dich-vu-go-shopping-mua-ho',
  'aha-giao-hang-sieu-toc': 'aha-sieu-toc-do-an',
  'gioi-thieu-merchant-capichi': 'capichi-khong-chi-la-nhung-bua-an-ngon',
  'danh-muc-hang-hoa-duoc-phep-van-chuyen-theo-quy-dinh':
    'danh-muc-hang-hoa-duoc-phep-van-chuyen-theo-quy-dinh',
  'ahamove-x-momo-giup-nhau-vuot-dich':
    'ahamove-x-momo-giup-nhau-vuot-dich-nhan-tien-bang-momo-nhan-them-qua-thiet-thuc',
  'dam-bao-don-hang-danh-cho-khach-hang':
    'tinh-nang-dam-bao-hang-hoa-an-tam-giao-nhan-boi-thuong-nhanh-chong-den-100percent-gia-tri-hang',
  'gioi-thieu-doi-tac-haravan':
    'haravan-cong-ty-cong-nghe-cung-hanh-trinh-make-commerce-better',
  'quan-trong-thay-doi-chinh-sach-thue-thu-nhap-ca-nhan-doi-voi-cac-khoan-thuong':
    'quan-trong-thay-doi-chinh-sach-thue-thu-nhap-ca-nhan-doi-voi-cac-khoan-thuong',
  'xe-tai-vang': 'thanh-pho-aha-dieu-ky',
  'them-ban-them-qua-2022':
    'them-ban-them-qua-nhan-ngay-ma-giam-100percent-and-thuong-thu-hang-den-500000d',
  'cod-va-khai-gia-dich-vu-aha-pro':
    'tuy-chon-don-hang-co-thu-ho-tren-ung-dung-ahamove-va-muc-den-buboi-hoan-khi-co-su-co',
  'ahamove-driver-privacy-policy': 'driver-privacy-policy',
  'privacy-policy-driver': 'ahamoves-driver-privacy-policy',
  price: 'dich-vu-va-bang-gia-ahamove',
  'guarantee-policy': 'chinh-sach-boi-thuong-hang-hoa-mat-mat-hu-hong',
};

export const instapageMappingData: Record<string, string> = {
  'giao-hang-4h': 'https://home.ahamove.com/giao-hang-4h/',
  'dang-ky-giao-hang-lien-tinh':
    'https://home.ahamove.com/dang-ky-giao-hang-lien-tinh/',
  'ahamove-haravan': 'https://home.ahamove.com/ahamove-haravan/',
  'aha-sieu-toc-do-an': 'https://home.ahamove.com/aha-sieu-toc-do-an',
  'thanh-pho-aha-dieu-ky': 'https://home.ahamove.com/thanh-pho-aha-dieu-ky',
  'xe-tai-vang': 'https://home.ahamove.com/thanh-pho-aha-dieu-ky',
  'tuyen-xe-vung-tau': 'https://home.ahamove.com/tuyen-xe-vung-tau/',
  'tuyen-xe-hai-phong': 'https://home.ahamove.com/tuyen-xe-hai-phong/',
  'tai-xe-da-nang': 'https://home.ahamove.com/tai-xe-da-nang/',
  'tuyen-tai-xe-da-lat': 'https://home.ahamove.com/tuyen-tai-xe-da-lat/',
  'tai-xe-ha-noi': 'https://home.ahamove.com/tai-xe-ha-noi/',
  hoatnaocungshell: 'https://home.ahamove.com/hoatnaocungshell/',
  uudaichotaixeahamove: 'https://home.ahamove.com/uudaichotaixeahamove/',
  'tai-xe-dong-nai': 'https://home.ahamove.com/tai-xe-dong-nai/',
  'tai-xe-xe-tai': 'https://home.ahamove.com/tai-xe-xe-tai/',
  'tai-xe-binh-duong': 'https://home.ahamove.com/tai-xe-binh-duong/',
  'xe-tai-ha-noi': 'https://home.ahamove.com/xe-tai-ha-noi/',
  'xac-nhan-tx-truck': 'https://home.ahamove.com/xac-nhan-tx-truck/',
  'huong-dan-thao-tac-xe-tai':
    'https://home.ahamove.com/huong-dan-thao-tac-xe-tai/',
  'quy-trinh-thuc-hien-don-hang-xe-tai':
    'https://home.ahamove.com/quy-trinh-thuc-hien-don-hang-xe-tai/',
  'ho-tro-giai-dap-thac-mac':
    'https://home.ahamove.com/ho-tro-giai-dap-thac-mac/',
  'giai-dap-thac-mac-xe-may':
    'https://home.ahamove.com/giai-dap-thac-mac-xe-may/',
  'quy-trinh-thuc-hien-don-hang':
    'https://home.ahamove.com/quy-trinh-thuc-hien-don-hang/',
  'cac-quy-chuan-tu-ahamove':
    'https://home.ahamove.com/cac-quy-chuan-tu-ahamove/',
  'huong-dan-cac-thao-tac-tren-ung-dung':
    'https://home.ahamove.com/huong-dan-cac-thao-tac-tren-ung-dung/',
  'cam-nang-xe-tai': 'https://home.ahamove.com/cam-nang-xe-tai/',
  'cam-nang-xe-may': 'https://home.ahamove.com/cam-nang-xe-may/',
  'cam-nang-tai-xe': 'https://home.ahamove.com/cam-nang-tai-xe',
  'customer-community': 'https://home.ahamove.com/cong-dong-khach-hang',
  'xac-nhan-tx': 'https://home.ahamove.com/xac-nhan-tx',
  'shipper-cong-nghe': 'https://home.ahamove.com/shipper-cong-nghe',
  'xac-nhan-tx-sgn': 'https://home.ahamove.com/xac-nhan-tx-sgn',
  'tro-thanh-tai-xe-ahamove':
    'https://home.ahamove.com/tro-thanh-tai-xe-ahamove',
  'shipper-cong-nghe-sgn': 'https://home.ahamove.com/shipper-cong-nghe-sgn',
  'tai-xe-cong-nghe-ahamove-sgn':
    'https://home.ahamove.com/tai-xe-cong-nghe-ahamove-sgn',
  'dang-ky-tai-xe-ahamove-sgn':
    'https://home.ahamove.com/dang-ky-tai-xe-ahamove-sgn',
  'dang-ky-shipper-hn': 'https://home.ahamove.com/dang-ky-shipper-hn',
  'dang-ky-shipper-sgn': 'https://home.ahamove.com/dang-ky-shipper-sgn',
  'dang-ky-shipper-hn-video':
    'https://home.ahamove.com/dang-ky-shipper-hn-video',
  'dang-ky-shipper-autosms-\bsgn':
    'https://home.ahamove.com/dang-ky-shipper-autosms-\bsgn',
  'ga-khach-hang': 'https://home.ahamove.com/ga-khach-hang',
  ahacup2019hn: 'https://home.ahamove.com/ahacup2019hn',
  ahacup2019hcm: 'https://home.ahamove.com/ahacup2019hcm',
  'dangky-taixe-HN-hien': 'https://home.ahamove.com/dangky-taixe-HN-hien',
  'dangky-taixe-sgn-hien': 'https://home.ahamove.com/dangky-taixe-sgn-hien',
  'autosms-dang-ky-tx-sgn': 'https://home.ahamove.com/autosms-dang-ky-tx-sgn',
  list_tuyen_dung_tai_xe_HN:
    'https://home.ahamove.com/list_tuyen_dung_tai_xe_HN',
  list_tuyen_dung_tai_xe_HN_2:
    'https://home.ahamove.com/list_tuyen_dung_tai_xe_HN_2',
  'tap-chi-tai-xe-t7': 'https://home.ahamove.com/tap-chi-tai-xe-t7',
  'tap-chi-tai-xe-t8': 'https://home.ahamove.com/tap-chi-tai-xe-t8',
  'tap-chi-tai-xe-t9': 'https://home.ahamove.com/tap-chi-tai-xe-t9',
  'dang-ky-tai-xe-BN': 'https://home.ahamove.com/dang-ky-tai-xe-BN',
  'tap-chi-tai-xe-t10': 'https://home.ahamove.com/tap-chi-tai-xe-t10',
  'dang-ky-tai-xe-BD': 'https://home.ahamove.com/dang-ky-tai-xe-BD',
  'dang-ky-tai-xe-DN': 'https://home.ahamove.com/dang-ky-tai-xe-DN',
  'tai-xe-ahamove': 'https://home.ahamove.com/cong-dong-tai-xe-ahamove/',
  'tap-chi-tai-xe-t11': 'https://home.ahamove.com/tap-chi-tai-xe-t11',
  'dang-ky-tai-xe-HP': 'https://home.ahamove.com/dang-ky-tai-xe-HP',
  'tap-chi-tai-xe-t12': 'https://home.ahamove.com/tap-chi-tai-xe-t12',
  'dang-ky-tai-xe-HN': 'https://home.ahamove.com/dang-ky-tai-xe-HN',
  'dang-ky-tai-xe-Aha': 'https://home.ahamove.com/dang-ky-tai-xe-Aha',
  'dang-ky-taixe-Truck': 'https://home.ahamove.com/dang-ky-taixe-Truck',
  'dang-ky-txe-VungTau': 'https://home.ahamove.com/dang-ky-txe-VungTau',
  'tuyen-taixe-CanTho': 'https://home.ahamove.com/tuyen-taixe-CanTho',
  'xac-nhan-tx-Truck': 'https://home.ahamove.com/xac-nhan-tx-Truck',
  'dang-ky-taixe-DaNang': 'https://home.ahamove.com/dang-ky-taixe-DaNang',
  'tap-chi-tai-xe-t3-21': 'https://home.ahamove.com/tap-chi-tai-xe-t3-21',
  'Aha-se-chia': 'https://home.ahamove.com/Aha-se-chia',
  'tap-chi-tai-xe-t4-21': 'https://home.ahamove.com/tap-chi-tai-xe-t4-21',
  'dang-ky-khach-hang-Truck':
    'https://home.ahamove.com/dang-ky-khach-hang-Truck',
  'aha-se-chia-thank-you': 'https://home.ahamove.com/aha-se-chia-thank-you',
  'tap-chi-tai-xe-t5-21': 'https://home.ahamove.com/tap-chi-tai-xe-t5-21',
  'dang-ky-taixe-HaNoi': 'https://home.ahamove.com/dang-ky-taixe-HaNoi',
  'dang-ky-taixe-SGN': 'https://home.ahamove.com/dang-ky-taixe-SGN',
  'tap-chi-tai-xe-t6-21': 'https://home.ahamove.com/tap-chi-tai-xe-t6-21',
  POSsupportlivechat: 'https://home.ahamove.com/POSsupportlivechat',
  'tap-chi-tai-xe-t7-21': 'https://home.ahamove.com/tap-chi-tai-xe-t7-21',
  'tai-xe-Truck': 'https://home.ahamove.com/tai-xe-Truck',
  'khach-hang-Truck-ChoTot': 'https://home.ahamove.com/khach-hang-Truck-ChoTot',
  'dang-ky-khach-hang-grocery':
    'https://home.ahamove.com/dang-ky-khach-hang-grocery',
  'dang-ky-khach-hang-grocery-seeding':
    'https://home.ahamove.com/dang-ky-khach-hang-grocery-seeding',
  'dang-ky-khach-hang-trung-thu':
    'https://home.ahamove.com/dang-ky-khach-hang-trung-thu',
  'dang-ky-tai-xe-han-test-caresoft':
    'https://home.ahamove.com/dang-ky-tai-xe-han-test-caresoft',
  'dang-ky-tai-xe-truck-BD': 'https://home.ahamove.com/dang-ky-tai-xe-truck-BD',
  'dang-ky-khach-hang-truck-BD':
    'https://home.ahamove.com/dang-ky-khach-hang-truck-BD',
  'dang-ky-truck-banerwebside':
    'https://home.ahamove.com/dang-ky-truck-banerwebside',
  'dang-ky-tai-xe-truck-hph':
    'https://home.ahamove.com/dang-ky-tai-xe-truck-hph',
  'cau-hoi-xe-tai': 'https://home.ahamove.com/cau-hoi-xe-tai',
  'cau-hoi-xe-may': 'https://home.ahamove.com/cau-hoi-xe-may',
  'thu-vien-xe-tai': 'https://home.ahamove.com/thu-vien-xe-tai',
  'thu-vien-xe-may': 'https://home.ahamove.com/thu-vien-xe-may',
  'dang-ky-khach-hang-truck-HP':
    'https://home.ahamove.com/dang-ky-khach-hang-truck-HP',
  'san-xe-tai-vang': 'https://home.ahamove.com/san-xe-tai-vang',
  'Dangky-taixe-BinhDuong': 'https://home.ahamove.com/Dangky-taixe-BinhDuong',
  'ahamove-tai-xe-tong-ket-2021':
    'https://home.ahamove.com/ahamove-tai-xe-tong-ket-2021',
  'ahamove-tai-xe-gioi-thieu-2022':
    'https://home.ahamove.com/ahamove-tai-xe-gioi-thieu-2022',
  'Dangky-taixe-DaNang': 'https://home.ahamove.com/Dangky-taixe-DaNang',
  'dangky-taixe_Vinh': 'https://home.ahamove.com/dangky-taixe_Vinh',
  'uu-dai-tai-xe-Ahamove': 'https://home.ahamove.com/uu-dai-tai-xe-Ahamove',
  'quy-trinh-giao-nhan-hang':
    'https://home.ahamove.com/quy-trinh-giao-nhan-hang',
  'thao-tac-ung-dung': 'https://home.ahamove.com/thao-tac-ung-dung',
  'quy-tac-ung-xu': 'https://home.ahamove.com/quy-tac-ung-xu',
  'thac-mac-va-ho-tro': 'https://home.ahamove.com/thac-mac-va-ho-tro',
  'trao-yeu-thuong': 'https://home.ahamove.com/trao-yeu-thuong',
  'dangky-taixe-BuonMaThuot':
    'https://home.ahamove.com/dangky-taixe-BuonMaThuot',
};

export const postSlugMappingData: Record<string, string> = {
  'huong-dan-chi-tiet-ve-dich-vu-hub-tmdt': 'quy-trinh-hub-tmdt',
  bikepooldg: 'quy-trinh-giao-hang-dich-vu-co-ban',
  'trien-khai-dich-vu-giao-hang-mua-dich-sieu-toc-lien-quan':
    'sieutoclienquanahamove',
  'han-huong-dan-dang-ky-vn-eid-va-lam-can-cuoc-cong-dan-gan-chip':
    'vneid_cccdganchip',
  'huong-dan-cap-nhat-thong-tin-cac-giay-to-lien-quan-covid-19':
    'huong-dan-cap-nhap-thong-tin-vaccine',
  'quy-chuan-dong-phuc': 'quychuan_dongphuc',
  'doi-chuyen-vung-tau': 'doi-chuyen-vt-22032021',
  'doi-chuyen-da-nang': 'doi-chuyen-dn-26042021',
  'doi-chuyen-bien-hoa': 'doi-chuyen-bh-31082020',
  'doi-chuyen-binh-duong': 'doi-chuyen-bd-17052021',
  'doi-chuyen-hai-phong': 'doi-chuyen-hp-08032021',
  'doi-chuyen-can-tho': 'doi-chuyen-ct-03032021',
  'quy-trinh-dich-vu-mua-ho': 'quytrinhahamart',
  'sieu-toc-kho-thuong-to-thu-nhap-khung': 'stk-thuong-cuc-to',
  '2haha': 'quy-trinh-dich-vu-2h',
  'danh-sach-tai-xe-bi-trung-cmnd-tao-ma-so-thue-tai-xe-co-hai-ma-so-thue':
    'trungmst',
  'v2-ke-hoach-thuc-hien-nghia-vu-thue-2019': 'thue-2019',
  'nw-thong-tin-giai-bong-da-ahacup-2022': 'ahacup2021nw',
  'chat-luong-2019-2': 'chatluong2019-2',
  'hub-lmdhn-chinh-sach-ho-tro-thu-nhap-hang-tuan-01-07-07-2019':
    'hub-mmdhn-chinh-sach-ho-tro-thu-nhap-hang-tuan-01-07-07-2019',
  'ahamove-shopeepay-thang-11': 'ahamove-shopeepay-uu-dai',
  sgneewsh201019: 'sgneewsh201123',
  'tri-truckahamove': 'dich-vu-ba-gac-tai-tai-van',
  diver_statistic_2020_cancellation_rate: 'acceptance-rate-2',
  'gioi-thieu-ban-binh-duong-nhan-thuong-ngay-500k': 'driver-referral-bdg',
  'phuong-thuc-thanh-toan-tien-thu-ho-don-hang': 'thanhtoancod',
  'huong-dan-dang-ky-truc-tuyen-doi-tac-xe-may': 'dang-ky-truc-tuyen',
  'quy-trinh-tai-kich-hoat-tai-khoan-danh-cho-doi-tac-lau-ngay-khong-hoat-dong':
    'quydinhkiquy',
  xacthuchinhanh99: 'xacthucgiaotra',
  'doi-chuyen-sieu-toc-do-an': 'doi-chuyen-sieu-toc-do-an-han-120922',
  'hot-tinh-nang-moi-aha-chien-binh-ra-doi': 'aha-chien-binh',
  tongdai_hotro: 'huong-dan-lien-he-tong-dai-ahamove',
  'exp-t11-gioi-thieu-mot-nhan-thuong-hai':
    'exp-t12-gioi-thieu-mot-nhan-thuong-hai',
  'xe-tai-16-8-thong-bao-gia-han-giay-nhan-dien-qr-da-cap-cho-cac-phuong-tien':
    'huong-dan-hoat-dong-phuong-tien-xe-tai',
  sieutocnoiquanahamovehan: 'ra-mat-dich-vu-moi-sieu-toc-lien-quan',
  'dich-vu-giao-do-an': 'quy-trinh-giao-hang-dich-vu-giao-do-an',
  'acceptance-rate': 'ty-le-chap-nhan',
  diver_statistic_2020_acceptance_rate: 'ty-le-chap-nhan',
  diver_statistic_2020_rating: 'muc-danh-gia',
  'tui-giu-nhiet-chuyen-nghiep-tung-giay-han':
    'tui-giu-nhiet-chuyen-nghiep-tung-giay',
  '10-nguyen-tac-vang-cho-doi-tac-moi': '10-bi-kip-vang-cho-doi-tac-moi',
  bikiptx: '10-bi-kip-vang-cho-doi-tac-moi',
  'bang-gia-va-cach-tinh-thue-moi': 'hn-bang-gia-va-cach-tinh-thue-moi',
  'hn-thuong-tai-xe-lan-dau-chay-dich-vu-tmdt-1718-01-2019':
    'TMDT_17012019_BONUS',
  '18-01-2019-thu-6-ve-thuong-tran-tre':
    '18-01-2019-thu-6-ve-thuong-tran-tre-low',
  'chinh-sach-thuong-8-3-2019': 'eventpolicy_08032019',
  'sgn-chinh-sach-thuong-tuan-04-03-10-03-flex':
    'sgn-chinh-sach-thuong-tuan-04-03-10-03',
  '08-03-don-bao-la-low': '08-03-don-bao-la',
  '08-03-don-bao-la-high': '08-03-don-bao-la',
  'hnchuong-trinh-thuong-tuan-11-3-17-3': 'hnchuong-trinh-thuong-tuan-high-non',
  'sgn-chuong-trinh-thuong-dich-vu-giao-do-an-25-28-4':
    'sgn-chuong-trinh-thuong-dich-vu-giao-do-an-25-28-4-B',
  '16238-2': 'han-food',
  'huong-dan-chup-anh-xac-nhan-giao-hang-thanh-cong-va-thong-bao-hoan-tra':
    'huong-dan-xac-nhan-giao-hang-thanh-cong-va-thong-bao-hoan-tra',
  'hub-hmdsg-chinh-sach-ho-tro-thu-nhap-hang-tuan-13-19-05-2019':
    'hub-hmmdsg-chinh-sach-ho-tro-thu-nhap-hang-tuan-13-19052019',
  'hnsdc-13-05-2019-biet-doi-dich-vu-trong-ngay-quyen-loi-cuc-day': '18151-2',
  '17638-2': 'dutop_chuyenhub_02_04_05',
};
